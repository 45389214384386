import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import { Text } from "@legion-ui/core";
import FontFamily from "../../../components/FontFamily.jsx";
import StorybookEmbed from "../../../components/StorybookEmbed.jsx";
import StorybookEmbedTypography from "../../../components/StorybookEmbedTypography.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Usage`}</h2>
    <p>{`Typography is defined as the science of style, appearance, and structure of fonts that aim to provide convenience and aesthetics to the reader. Effective typography depends not only on the content but also on the presentation visual of the typography itself, starting from the font type, font size and line height. You can chek out the guideline for more detail description`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Text } from "@legion-ui/core";
`}</code></pre>
    <FontFamily mdxType="FontFamily" />
    <div className="divi" />
    <h2>{`Heading`}</h2>
    <p>{`You can set the text to a heading text by giving as props value of either: `}<inlineCode parentName="p">{`h1`}</inlineCode>{`, `}<inlineCode parentName="p">{`h2`}</inlineCode>{`, `}<inlineCode parentName="p">{`h3`}</inlineCode>{`, `}<inlineCode parentName="p">{`h4`}</inlineCode>{`, `}<inlineCode parentName="p">{`h5`}</inlineCode>{`, or `}<inlineCode parentName="p">{`h6`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  <Text as="h1">Heading 1</Text>
  <Text as="h2">Heading 2</Text>
  <Text as="h3">Heading 3</Text>
  <Text as="h4">Heading 4</Text>
  <Text as="h5">Heading 5</Text>
  <Text as="h6">Heading 6</Text>
`}</code></pre>
    <div className="divi" />
    <h2>{`Others`}</h2>
    <p>{`There are also a lot of other HTML5 Semantic elements that are available to be used from our Text component, such as `}<inlineCode parentName="p">{`span`}</inlineCode>{`, `}<inlineCode parentName="p">{`p`}</inlineCode>{`, `}<inlineCode parentName="p">{`del`}</inlineCode>{`, `}<inlineCode parentName="p">{`sup`}</inlineCode>{`, `}<inlineCode parentName="p">{`sub`}</inlineCode>{`, and `}<inlineCode parentName="p">{`a`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  <Text as="span">Span</Text>
  <Text as="p">Paragraf</Text>
  <Text as="del">Del</Text>
  <Text as="sup">Sup</Text>
  <Text as="sub">Sub</Text>
  <Text as="a">Link</Text>
`}</code></pre>
    <div className="divi" />
    <h2>{`Size`}</h2>
    <p>{`There are many sizes that we offer from our text component, which should be an option for you to suit all your needs and it is connected to the token that you already set too! Some of these options are as follows `}<inlineCode parentName="p">{`h1`}</inlineCode>{`, `}<inlineCode parentName="p">{`h2`}</inlineCode>{`, `}<inlineCode parentName="p">{`h3`}</inlineCode>{`, `}<inlineCode parentName="p">{`h4`}</inlineCode>{`, `}<inlineCode parentName="p">{`h5`}</inlineCode>{`, `}<inlineCode parentName="p">{`h6`}</inlineCode>{`, `}<inlineCode parentName="p">{`lg_regular`}</inlineCode>{`, `}<inlineCode parentName="p">{`lg_semibold`}</inlineCode>{`, `}<inlineCode parentName="p">{`lg_bold`}</inlineCode>{`, `}<inlineCode parentName="p">{`sm_regular`}</inlineCode>{`, `}<inlineCode parentName="p">{`sm_semibold`}</inlineCode>{`, and `}<inlineCode parentName="p">{`sm_bold`}</inlineCode>{`.`}</p>
    <p>{`Or you can also enter a string with your own measure if that’s not enough.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  <Text size="h1">Heading 1</Text>
  <Text size="h2">Heading 2</Text>
  <Text size="h3">Heading 3</Text>
  <Text size="h4">Heading 4</Text>
  <Text size="h5">Heading 5</Text>
  <Text size="h6">Heading 6</Text>
  <Text size="lg_regular">Large Regular</Text>
  <Text size="lg_semibold">Large Semibold</Text>
  <Text size="lg_bold">Large Bold</Text>
  <Text size="sm_regular">Small Regular</Text>
  <Text size="sm_semibold">Small Semibold</Text>
  <Text size="sm_bold">Small Bold</Text>
`}</code></pre>
    <h2>{`Responsive`}</h2>
    <p>{`You can also make the font size responsive based on the screen size.`}</p>
    <p>{`We have several ways on how to implement it.`}</p>
    <h3><strong parentName="h3">{`Default`}</strong></h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Text as="h1" size={["h1", "h2", "h3", "h4", "h5"]}>
  Heading
</Text>
`}</code></pre>
    <h3><strong parentName="h3">{`Responsive Object`}</strong></h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Text
  as="h1"
  size={{
    lg: "h2",
    md: "h3",
    sm: "h4",
    xl: "h1",
    xs: "h5",
  }}
>
  Heading
</Text>
`}</code></pre>
    <h3><strong parentName="h3">{`Responsive Relative`}</strong></h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Text as="h1" size={["h1", "", "34px", "24px", "14px"]}>
  Heading
</Text>
`}</code></pre>
    <h3><strong parentName="h3">{`Responsive Absolute`}</strong></h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Text as="h1" size={["50px", "44px", "34px", "24px", "14px"]}>
  Heading
</Text>
`}</code></pre>
    <div className="divi" />
    <h2>{`Color`}</h2>
    <p>{`As much as you can have text, you need them colored too in some cases and we hope we delivered what you need to in this section. As for which color do we use, you can use your ColorsType Interface or any hex/rgb value you want to input really into our color props.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Component`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Color`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><Text size="16px" color="primary400" mdxType="Text">{`Primary 400`}</Text></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`primary400`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><Text size="16px" color="secondary400" mdxType="Text">{`Secondary 400`}</Text></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`#528BFF`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  <Text color="primary400">Primary 400</Text>
  <Text color="#528BFF">#528BFF</Text>
`}</code></pre>
    <StorybookEmbed storybookId="component-text--default" mdxType="StorybookEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      